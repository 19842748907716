.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
    background-color: rgba(0, 0,0, 0.1);
}
.css-jq6zhv-MuiPaper-root-MuiDialog-paper{
    box-shadow: none;
}
.css-1fj2gmw-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    border-radius: 14px;
    border-color:white;
    box-shadow: 1px;
}
/* .MuiBackdrop-root{
    background-color: rgba(0, 0,0, 0.5);
} */